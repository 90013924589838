<template>
  <v-container fluid>
    <v-data-table
      :headers="headers"
      :items="items.items"
      item-key="id"
      class="elevation-0 background-transparent"
      :items-per-page="25"
      :loading="loading"
      :options.sync="options"
      :server-items-length="items.meta.totalItems"
      :footer-props="{
        'items-per-page-options': rowsPerPageItemsGlobal
      }"
    >
      <template v-slot:top>
        <div class="d-inline-flex justify-space-between align-center pr-2 mb-5" style="height: 62px; width: 100%">

          <!-- Left-aligned search field -->
          <div class="d-inline-flex align-center">
            <v-text-field
              v-model="search"
              class="ml-5 custom-search-perm"
              outlined
              clearable
              dense
              hide-details
              :label="$lang.labels.searchByName"
              data-cy="search-by-name"
            ></v-text-field>
          </div>

          <!-- Right-aligned buttons -->
          <div class="d-inline-flex align-center ml-auto">
            <v-btn
              text
              light
              class="ml-2"
              color="primary"
              @click="$emit('fetchRoles', { options: options, search: search.length > 1 ? search : '' })"
            >
              <v-icon dense small>mdi-refresh</v-icon>
            </v-btn>
            <v-btn
              v-if="roleChecker(roles, ['ROLE_CREATOR', 'SUPER_USER'])"
              outlined
              color="accent"
              class="color-accent-text mr-1"
              :to="{ name: 'roleCreate' }"
            >
              <v-icon
                left
                dark
              >
                mdi-file-plus
              </v-icon>
              {{ $lang.actions.create }}
            </v-btn>
          </div>

        </div>
      </template>

      <template v-slot:item.id="{ item }">
        <div class="font-weight-bold"># {{ item.id }}</div>
      </template>

      <template v-slot:item.name="{ item }">
        <div class="d-flex justify-space-between align-center">
          <!-- Name on the left with a fixed or max width -->
          <div class="text-start name-container clickable-simple"> <!-- Adjust the margin-right here -->
            <router-link 
              v-if="item.name.length < 86"
              :disabled="item.isSystem" 
              class="text-decoration-none" 
              :to="{ name: 'roleEdit', params: { id: item.id } }"
            >
              <span class="font-weight-regular color-primary">{{ item.name }}</span>
            </router-link>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <router-link
                    :disabled="item.isSystem"
                    class="text-decoration-none"
                    :to="{ name: 'roleEdit', params: { id: item.id } }"
                  >
                    <span class="font-weight-regular color-primary">{{ item.name.substring(0, 62) }}...</span>
                  </router-link>
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </div>
          <!-- 3 dots menu on the right -->
          <div>
            <v-menu class="custom-edit-menu" offset-y nudge-left="75" transition="slide-y-transition"> <!-- Adjust nudge-left if necessary -->
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  variant="text"
                  icon
                  v-on="on"
                >
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <!-- Menu with actions -->
              <v-card class="pr-2 pl-1" style="border: #CBD5E1 1px solid;">
                <v-list dense>

                  <!-- Copy Create button -->
                  <v-list-item
                    link
                    class="text--secondary font-weight-light"
                    @click="copyCreateResource(item, 'roleCreate', $router, '', $options.filters.formatDateTime(new Date()))"
                  >
                    {{ $lang.actions.copyCreate }}
                  </v-list-item>
                </v-list>
              </v-card>
            </v-menu>
          </div>
        </div>
      </template>

      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.modifiedOn="{ item }">
        <div>{{ item.modifiedOn | formatDateTime }}</div>
      </template>

      <template v-slot:item.isSystem="{ item }">
        <div>{{ item.isSystem ? $lang.labels.yes : $lang.labels.no }}</div>
      </template>

      <template v-slot:item.isAssignableToUser="{ item }">
        <div>{{ item.isAssignableToUser ? $lang.labels.yes : $lang.labels.no }}</div>
      </template>
    </v-data-table>
    <v-dialog v-if="showNameChange" v-model="showNameChange" max-width="33%">
      <name-modal :item="selectedItem" @update="saveRole" @closeDialog="showNameChange = false; selectedItem = null"></name-modal>
    </v-dialog>
  </v-container>
</template>

<script>
import NameModal from './RoleNameModal'
import { updateRoleUsingPUT as updateRole } from '@/utils/api'
import { mapState } from 'vuex'
import { bus } from '@/main'
import { copyCreateResource, roleChecker } from '@/utils/helpers'
import { mapActions } from 'vuex'
import { debounce } from 'lodash'

export default {
  components: {
    NameModal
  },
  props: {
    items: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lock: true,
      showNameChange: false,
      selectedItem: null,
      search: '',
      options: {},
      totalItems: 0,
      headers: [
        {
          text: this.$lang.labels.name,
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: this.$lang.labels.system, value: 'isSystem', sortable: true },
        { text: this.$lang.labels.assignable, value: 'isAssignableToUser', sortable: true }
      ],
      roles: []
    }
  },
  computed: {
    ...mapState('app', ['rowsPerPageItemsGlobal', 'userRoles', 'userSettings'])
  },
  watch: {
    options: {
      handler () {
        if (!this.lock) this.$emit('fetchRoles', { options: this.options, search: this.search.length > 1 ? this.search : '' })
        this.savePreFill()
      },
      deep: true
    },
    search: {
      handler: debounce( function (val) {
        if (!this.lock && val && val.length > 1) this.$emit('fetchRoles', { options: this.options, search: val, resetPage: true })
        if (!this.lock && val === null || val === '') this.$emit('fetchRoles', { options: this.options, search: '', resetPage: true })
        this.savePreFill()
        this.options.page = 1
      }, 500)
    }
  },
  mounted() {
    bus.$on('refreshData', (name) => {
      if (!this.lock && name === this.$route.name) this.$emit('fetchRoles', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
      this.options.page = 1
    })
  },
  created() {
    this.roles = this.userRoles.map((x) => x.name) || []

    if (this.userSettings.display.showModifiedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.modifiedOn, value: 'modifiedOn', sortable: true })
    }

    if (this.userSettings.display.showCreatedOn) {
      this.headers.splice(2, 0, { text: this.$lang.labels.createdOn, value: 'createdOn', sortable: true })
    }

    if (this.userSettings.display.showId) {
      this.headers.splice(0, 0, { text: this.$lang.header.id, value: 'id', sortable: true })
    }

    // if (this.roleChecker(this.roles, ['ROLE_CREATOR', 'SUPER_USER'])) {
    //   this.headers.push({ text: this.$lang.labels.actions, value: 'actions', align: 'end', sortable: false })
    // }

    if (localStorage.preFillData) {
      const preFill = JSON.parse(localStorage.preFillData)

      if (preFill && preFill.roleList) {
        this.search = preFill.roleList.search
        this.options = preFill.roleList.options
      }
    }
    this.$emit('fetchRoles', { options: this.options, search: this.search && this.search.length > 1 ? this.search : '', resetPage: true })
    setTimeout(() => this.lock = false, 100)
    this.options.page = 1
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    copyCreateResource,
    roleChecker,
    savePreFill() {
      if (!localStorage.preFillData) localStorage.preFillData = '{}'
      if (localStorage.preFillData) {
        const preFill = JSON.parse(localStorage.preFillData)

        if (!preFill.roleList) preFill.roleList = {
          search: '',
          options: {
            page: 1,
            itemsPerPage: 25,
            sortBy: [],
            sortDesc: []
          }
        }

        preFill.roleList.search = this.search
        preFill.roleList.options = { ...this.options, page: 1 }

        localStorage.preFillData = JSON.stringify(preFill)
      }
    },
    async saveRole(newName) {
      this.lock = true

      let res = null

      const { id } = this.selectedItem

      try {

        res = await updateRole({ id, body: { name: newName } })

        this.showNameChange = false

        if (res && res.status !== 200) {
          this.addSnackbar({
            message: this.$lang.errors.roleUpdate,
            timeout: 5000,
            color: 'error'
          })
          this.selectedItem = null
          this.lock = false

          return
        }
        this.$emit('updateName', { id, name: newName })
        this.addSnackbar({
          message: this.$lang.success.roleUpdated,
          timeout: 5000,
          color: 'success'
        })
        this.selectedItem = null

      } catch (err) {
        this.addSnackbar({
          message: err,
          timeout: 5000,
          color: 'error'
        })
        this.lock = false
      }
    }
  }
}
</script>
