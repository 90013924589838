<template>
  <div style="width: 100%;" class="custom-container">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :items="roles"
          :loading="loading"
          @activeOnly="activeOnlyFunction($event)"
          @fetchRoles="fetchRoles($event)"
          @updateName="updateName($event)"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './RolesDatatable'
import {
  getRolesUsingGET as getRoles
} from '@/utils/api'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      err: '',
      success: '',
      activeOnly: true,
      tableKey: 0,
      loading: false,
      roles: { items: [], meta: {} }
    }
  },
  computed: {},
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods: {
    ...mapActions('app', ['addSnackbar']),

    updateName(data) {
      this.roles.items.forEach((item) => {
        if (item.id === data.id) {
          item.name = data.name
        }
      })
    },
    activeOnlyFunction(event) {
      this.activeOnly = event.activeOnly

      this.fetchRoles(event.options)
    },
    fetchRoles(options) {
      this.loading = true

      const obj = {}

      if (options) {
        if (options.options && options.options.itemsPerPage !== -1) {
          obj.page = options.resetPage ? 1 : options.options.page || 1
          obj.size = options.options.itemsPerPage || 25
        } else {
          obj.page = 1
          obj.size = 25
        }
      } else {
        obj.page = 1
        obj.size = 25
      }

      if (options && options.search && options.search.length > 1) {
        obj.name = options.search
      }

      if (options?.options?.sortDesc && options.options.sortDesc.length > 0) obj.orderDirection = options.options.sortDesc[0] ? 'DESC' : 'ASC'

      if (options && options.options && options.options.sortBy && options.options.sortBy.length > 0) {
        obj.orderField = options.options.sortBy[0]
      }

      if (this.activeOnly) obj.status = 'ACTIVE'

      getRoles(obj)
        .then((res) => {
          this.roles = res.data.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>
